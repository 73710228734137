const data={"info":<>
<h1 style={{fontSize:"1.8rem",fontWeight:"600",textAlign:"left"}} st>Ansh Garg</h1> 
<div className="d-flex justify-content-center align-items-center pratha" style={{gap:"5rem"}} >

<div className="right-box-info">

<br/>
<ul className="pra-det">
    <li>I’m Ansh Garg, a B.Tech CSE student specializing in Full Stack AI. </li>
    <li>
        Technical Head, UPES-CSI.
    </li>
    <li>
        I lead projects in software development and AI, including GPS tracking and conflict prediction systems.
    </li>
    <li>
    Developed GPS tracking system for university buses.
    </li>
    <li>
    Created a faculty portal for efficient management.
    </li>
    <li>
    Built a human-wildlife conflict prediction system
    Specialized in full-stack development and AI projects
    </li>

</ul>
</div>
</div>
</>
}

export default data;