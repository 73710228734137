const data={"info":<>
    <h1 style={{fontSize:"1.8rem",fontWeight:"600",textAlign:"left"}} st>Deepanshu Miglani </h1> 
    <div className="d-flex justify-content-center align-items-center pratha" style={{gap:"5rem"}} >
    
    <div className="right-box-info">
    
    <br/>
    <ul className="pra-det">
        <li>
            I'm Deepanshu Miglani Pre-Final Year Student, AIML Enthusiast.
        </li>
        <li>
            Registration head,UPES-CSI
        </li>
    </ul>
    </div>
    </div>
    </>
    }
    
    export default data;