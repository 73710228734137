const data = {
    "info": <><h1 style={{ fontSize: "1.5rem", fontWeight: "600" }}>Pratham Kandari</h1>
        <br></br>
        <ul className="aayu-det">
            <li>
            Final year student at University of Petroleum and Energy Studies 
            (UPES), pursuing Bachelors of Technology in Computer Science 
            and Engineering with specialization in Cloud Computing
            </li>
            <li>
                Vice president, UPES-CSI.
            </li>
            <li>
            Grand Finalist Smart India Hackathon 2023
            </li>
            <li>
            Second Runner UPES-CSA Hackathon 3.0
            </li>
            <li>
                Received the award for BEST MENTOR at OPEN Community.
            </li>
            <li>
            Received the Star Performer Award being a part of the organizing committee at UPES-CSI Hackathon 7.0
            </li>
        </ul>
    </>
}

export default data;