const data = {
  info: (
    <>
      <h1 style={{ fontSize: "1.5rem", fontWeight: "600" }}>Kashish Deol</h1>
      <br></br>
      <ul className="aayu-det">
        <li>
          Pre-Final year student pursuing Btech Computer Science specialising in AIMl.
        </li>
        <li>
          Working Committee Head, UPES-CSI</li>
        <li>Student Placement Representative</li>
        <li> Organising committee member for multiple national and international hackathons organised at UPES.</li>
      </ul>
    </>
  ),
};

export default data;
