const data = {
    "info": <><h1 style={{ fontSize: "1.5rem", fontWeight: "600" }}>Anushka Chamoli</h1>
        <br></br>
        {/* <ul className="aayu-det">
            <li>
                I am Aryan Deopa, a final year B.Tech CSE student with specialisation in Graphics and Gaming.

            </li>
            <li>
                Currently serving as the Director of UPES-CSI

            </li>
            <li>
                Formerly held the position of Design & VFX Head of UPES-CSI
            </li>
            <li>
                Formerly held the position of Design & VFX Head and Photography Head in a National Hackathon named IBM Code Wars and an International Hackathon named UHackathon 4.0META .            </li>
            <li>
                Actively contributed in 2 different online Hackathons and 4 different offline Hackathons

            </li>
        </ul> */}
    </>
}

export default data;