const data = {
    "info": <>
        <h1 style={{ fontSize: "1.8rem", fontWeight: "600", textAlign: "left" }} st>Garima Sethi</h1>
        <div className="d-flex justify-content-center align-items-center pratha" style={{ gap: "5rem" }} >

            <div className="right-box-info">

                <br />
                <ul className="pra-det">
                    <li>I'm in my pre-final year, pursuing a Bachelor's of Technology in Computer Science with Data Science as my specialisation. My keen interest towards writing is what lead me to join UPES-CSI as a core member and now as the Editor-in-Chief.</li>
                    <li>Served as the anchor of several events including Hackathon 8.0, Tech-Hunt and Innovation.</li>
                </ul>
            </div>
        </div>
    </>
}

export default data;