import './slider.css'
import Slider from "react-slick";
import img from '../Gallery_Images/1.JPG'
import img1 from '../Gallery_Images/2.JPG'
import img2 from '../Gallery_Images/3.JPG'
import img3 from '../Gallery_Images/4.JPG'
import img4 from '../Gallery_Images/5.JPG'
import Heading from '../Heading/Heading';



const slider = () => {

  const images = [
    img,
    img1,
    img2,
    img3,
    img4,
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    loop: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    swipeToSlide: true,
  };



  return (
    <div>
      <Heading head="Gallery" size="50px" />

      <div className="slick-container-rext pt-3">
        <Slider {...settings}>
          {images.map((element) => {
            return <img src={element} className="rext" />;
          })}
        </Slider>
      </div>
    </div>
  );
};


export default slider;