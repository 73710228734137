const data = {
    "info": <><h1 style={{ fontSize: "1.5rem", fontWeight: "600" }}>Rimjhim Gupta</h1>
        <br></br>
        <ul className="aayu-det">
            <li>
            I am Rimjhim Gupta, Final year Student , B.Tech CSE CCVT
            </li>
            <li>
                General Secretary, UPES-CSI.
            </li>
            <li>
                Organizer for 3 national, 2 international Hackathon.
            </li>
            <li>
                Organizer for ICMLDE 2023, an international conference.
            </li>
            <li>
                SoCS Student Lead for OPEN DAY, UPES. 
            </li>
        </ul>
    </>
}

export default data;