const data={"info":<>
<h1 style={{fontSize:"1.8rem",fontWeight:"600",textAlign:"left"}} st>Aditya Goel</h1> 
<div className="d-flex justify-content-center align-items-center pratha" style={{gap:"5rem"}} >

<div className="right-box-info">

<br/>
<ul className="pra-det">
    <li>
        I'm Aditya Goyal, a CSE student at UPES and aspiring data scientist from IIT Madras, with skills in video editing and 2D animation.
    </li>
    <li>
        Ex Campus Executive@E-Cell IITB.
    </li>
</ul>
</div>


</div>


</>
}

export default data;