const data = {
    info: (
      <>
        <h1 style={{ fontSize: "1.5rem", fontWeight: "600" }}>Swasti Rathee</h1>
        <br></br>
        <ul className="aayu-det">
          <li>Pre-final year Btech Computer Science Student.</li>
          <li>Deputy Associate Treasurer, UPES-CSI</li>
        </ul>
      </>
    ),
  };
  
  export default data;
  