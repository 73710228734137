const data = {
    "info": <><h1 style={{ fontSize: "1.5rem", fontWeight: "600" }}>Rasya Mihir</h1>
        <br></br>
        <ul className="aayu-det">
            <li>
                I am Rasya Mihir a Final year student of Int.B.Sc -M.Sc Mathematics,also serving as the President of Computer Society of India in UPES            </li>
            <li>
                Organized 2 international Hackathons, 3National Hackathons, also the organiser of ICMLDE 2023,            </li>

        </ul>
    </>
}

export default data;