import React from "react";
import "./Team.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { motion, useAnimation } from "framer-motion";
import { useEffect, useState } from "react";
import i1 from "../Team/Team Images/rasya mihir.png";
import i2 from "../Team/Team Images/pratham1.png";
import i3 from "../Team/Team Images/Rimjhim1.png";
import i4 from "../Team/Team Images/Anushka.png";
import i5 from "../Team/Team Images/shubham1.png";
import i6 from "../Team/Team Images/kashish1.png";
import i7 from "../Team/Team Images/Keshav1.png";
import i8 from "../Team/Team Images/swasti1.png";
import i9 from "../Team/Team Images/Rishi1 (1).png";
import i16 from "../Team/Team Images/Jiya Bajaj1.png";
import i17 from "../Team/Team Images/Akriti.png";
// import i18 from "../Team/Team Images/tuba.png";
import i19 from "../Team/Team Images/garima1.png";
import i14 from "../Team/Team Images/Kartikay1.png";
// import i20 from "../Team/Team Images/mahim1.png";
import i10 from "../Team/Team Images/Manvee1.png";
import i21 from "../Team/Team Images/Ansh.png";
import i22 from "../Team/Team Images/Abhinav-removebg-preview (1).png";
// import i23 from "../Team/Team Images/Arnav.png";
import i26 from "../Team/Team Images/Deepanshu.png";
import i24 from "../Team/Team Images/Saksham.png";
import i29 from "../Team/Team Images/Aditya-removebg-preview (1).png";
import i25 from "../Team/Team Images/yash1.png";
import i15 from "../Team/Team Images/sparsh.png";

import i31 from "../Team/Team Images/sughandha_mam.png";
import i30 from "../Team/Team Images/shubhi_mam (2).png"

import { FaLinkedin } from "react-icons/fa";
import Heading from "../../Components/Heading/Heading";
import aayushman from './Team Details/Pratham';
import devasish from './Team Details/Rimjhim';
import ansh from './Team Details/Ansh.js';
import abhinav from './Team Details/Abhinav.js'
import sughanda from './Team Details/Sughanda';
import abhipriya from './Team Details/Rasya';
import aryan from './Team Details/Anushka';
import Kashish from './Team Details/Kashish';
import Divyansh from './Team Details/Shubham';
import Keshav from './Team Details/Keshav';
import shubhimam from './Team Details/Shubhimam';
import aditya from './Team Details/Aditya.js'
import akriti from './Team Details/Akriti.js';
import garima from './Team Details/Garima.js';
import kartikay from './Team Details/Kartikay.js'
// import mahim from './Team Details/Mahim.js';
import manvee from './Team Details/Manvee.js'
import mitanshi from './Team Details/Saksham.js';
import yash from './Team Details/Yash.js';
// import arnav from './Team Details/Arnav.js';
import deepanshu from './Team Details/Deepanshu.js'
import rishi from './Team Details/Rishi.js';
import jiya from './Team Details/Jiya.js'
import sparsh from './Team Details/Sparsh.js'
import swasti from './Team Details/Swasti.js'
// import tuba from './Team Details/Tuba.js'

const Team = () => {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(aayushman);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  // create a array of name of team member and their designation
  const [Faculty, setFaculty] = useState([
    {
      image: i30,
      name: "Shubhi Sharma",
      designation: "Student Branch Coordinator",
      button: shubhimam,
      http: "https://www.linkedin.com/in/shubhi-sharma-6443a322/"
    },
    {
      image: i31,
      name: "Sugandha Sharma",
      designation: "Faculty Coordinator",
      button: sughanda,
      http: "https://www.linkedin.com/in/sugandha-sharma-00001/"

    },
  ]);

  const [team, setTeam] = useState([
    {
      image: i1,
      name: "Rasya Mihir",
      designation: "President",
      button: abhipriya,
      http: "https://www.linkedin.com/in/rasya-mihir-3a108a138/"

    },
    {
      image: i2,
      name: "Pratham Kandari",
      designation: "Vice President",
      button: aayushman,
      http: "linkedin.com/in/pratham-kandari"
    },
    {
      image: i3,
      name: "Rimjhim Gupta",
      designation: "General Secretary",
      button: devasish,
      http: "https://www.linkedin.com/in/rimjhim-gupta/"
    },
    {
      image: i4,
      name: "Anushka Chamoli",
      designation: "Director",
      button: aryan,
      http: "https://www.linkedin.com/in/anushka-chamoli-324b98227/"
    },
    {
      image: i5,
      name: "Shubham Sahu",
      designation: "Joint Secretary",
      http: "https://www.linkedin.com/in/shubham-sahu-892751262",
      button: Divyansh
    },
    {
      image: i6,
      name: "Kashish Deol",
      designation: "Working Committee Head",
      http: "https://www.linkedin.com/in/kashish-deol-584a72253?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
      button: Kashish
    },
    {
      image: i7,
      name: "Keshav Garg",
      button: Keshav,
      designation: "Associate Treasurer",
      http: "www.linkedin.com/in/keshav-garg0709"
    },
    // Dep Asso. Treasure
    {
      image: i8,
      name: "Swasti Rathee",
      designation: "Dep. Assoc. Treasure",
      button: swasti,
      http: "https://www.linkedin.com/in/swasti-rathee-80559127a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
      image: i9,
      name: "Rishi Raj Jain",
      button: rishi,
      designation: "Associate PR Head",
      http: "https://www.linkedin.com/in/rishi-raj-jain-368762253/"
    },
    {
      image: i16,
      name: "Jiya Bajaj",
      button: jiya,
      designation: "Associate PR Head",
      http: "https://www.linkedin.com/in/jiya-bajaj-439539259/"
    },
    {
      image: i17,
      name: "Akriti Negi",
      designation: "Design & VFX Head",
      button: akriti,
      http: "https://www.linkedin.com/in/akritinegi/"
    },
    // {
    //   image: i18,
    //   name: "Tuba Khan",
    //   designation: "Associate Design & VFX Head",
    //   button: tuba,
    //   http: "https://www.linkedin.com/in/tubakhxn"
    // },
    {
      image: i19,
      name: "Garima Sethi",
      designation: "Editor In Chief",
      button: garima,
      http: "https://www.linkedin.com/in/garima-sethi-582968248?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
      image: i14,
      name: "Kartikay Pandey",
      designation: "Associate Editor in Cheif",
      button: kartikay,

      http: "https://www.linkedin.com/in/kartikaypandey",
    },
    // {
    //   image: i20,
    //   name: "Mahim Sharma",
    //   designation: "Events Head",
    //   button: mahim,
    //   http: "https://www.linkedin.com/in/shaivi-sinha-33maso2826"
    // },
    // Associate Events Head
    {
      image: i10,
      name: "Manvee Singh",
      designation: "Events Head",
      button: manvee,
      http: "https://www.linkedin.com/in/manvee-singh-8842641b8?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
    },
    {
      image: i21,
      name: "Ansh Garg",
      designation: "Technical Head",
      button: ansh,
      http: "https://www.linkedin.com/in/ansh-garg-422950145?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
      image: i22,
      name: "Abhinav Giri",
      designation: "Associate Technical Head",
      button: abhinav,
      http: "https://www.linkedin.com/in/abhinav-giri-644707256/"
    },
    // {
    //   image: i23,
    //   name: "Arnav Vikal",
    //   designation: "Registration Head",
    //   button: arnav,
    //   http: "https://www.linkedin.com/in/pranjal-tripathi-a98048222/"
    // },
      {
        image: i26,
        name: "Deepanshu Miglani",
        designation: " Registrations Head",
        button:deepanshu,
        http: "https://www.linkedin.com/in/deepanshu-miglani-dsm"
    },
    {
      image: i24,
      name: "Saksham Narang",
      designation: "Management & Records Head",
      button: mitanshi,
      http: "https://www.linkedin.com/in/saksham-narang-165927249"
    },
    // Social Media Head
    {
      image: i29,
      name: "Aditya Goel",
      designation: "Social Media Head",
      button: aditya,
      http: "https://www.linkedin.com/in/aditya-goyal-007a91237?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
    },
    {
      image: i25,
      name: "Yash Tripathi",
      designation: "Photography Head",
      button: yash,
      http: "https://www.linkedin.com/in/yash-tripathi-a4201a261?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },

    //Cinematography Head
    {
      image: i15,
      name: "Sparsh Jain",
      designation: "Cinematography Head",
      button: sparsh,
      http: "https://www.linkedin.com/in/sparsh-jain-8649861b4?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"

    },
  ]);
  const [hoveredIndex, setHoveredIndex] = useState(null); // Add this line

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <div className="our-team">
      <Heading head="Team UPES-CSI" size="50px"></Heading>
      <Heading head="Our Faculty" size="30px"></Heading>
      <div
        className="d-flex align-items-center justify-content-center custom-grid flex-wrap"
        style={{ placeItems: "center" }}
      >
        {Faculty.map((member, index) => (
          <div
            onClick={function () { onOpenModal(); setUser(member.button); }}
            style={{ cursor: "pointer" }}
            key={index}
            className={`team-member  p-4  bg-white shadow-md  ${hoveredIndex === index ? "hovered" : ""
              }`}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="img ">
              <div className="img2">
                <div className="w-full h-0 pb-[100%] relative overflow-hidden rounded-md">
                  <img
                    className="absolute inset-0 w-auto  h-full object-cover object-center"
                    src={member.image}
                    alt="team"
                  />
                </div>
              </div>

              <div className="info text-left mt-2 flex items-center">
                <a
                  href={member.http}
                  className="z-10"
                  onClick={(e) => e.stopPropagation()}
                >
                  <FaLinkedin className="text-white mr-4 ml-2" size={40} />
                </a>
                <div>
                  <h3 className="text-xl font-semibold text-gray-100">
                    {member.name}
                  </h3>
                  <p className="text-gray-400">{member.designation}</p>
                </div>
              </div>
            </div>

          </div>
        ))}
      </div>
      <Heading head="Student Body" size="30px"></Heading>
      <div
        className="d-flex align-items-center justify-content-center flex-wrap custom-grid"
        style={{ placeItems: "center" }}
      >
        {team.map((member, index) => (
          <div
            onClick={function () {
              onOpenModal();
              setUser(member.button);
            }}
            style={{ cursor: "pointer" }}
            key={index}
            className={`team-member  p-4 pb-3 bg-white shadow-md  ${hoveredIndex === index ? "hovered" : ""
              }`}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="img">
              <div className="img2">
                <div className="w-full h-0 pb-[100%] relative overflow-hidden rounded-md">
                  <img
                    className="absolute inset-0 w-auto  h-full object-cover object-center"
                    src={member.image}
                    alt="team"
                  />
                </div>
              </div>

              <div className="info text-left mt-2 flex items-center">
                <a
                  href={member.http}
                  style={{ zIndex: "999" }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <FaLinkedin
                    className="white linked mr-4 ml-2"
                    color="white"
                  />
                </a>
                <div>
                  <h3 className="text-xl font-semibold text-gray-100">
                    {member.name}
                  </h3>
                  <p className="text-gray-400">{member.designation}</p>
                </div>
              </div>
            </div>

          </div>
        ))}
      </div>
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        classNames={{
          overlay: "customOverlay2",
          modal: "customModal2",
        }}
      >
        <div className="container-fluid team-modal backdrop-saturate-125 justify-content-center gap-5 text-white">
          {user.info}
        </div>
      </Modal>
    </div>
  );
};

export default Team;
