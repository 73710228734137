const data = [
    {
        img: require('./Arena3.0.png'),
        eventname: 'Arena 3.0',
        evantDate: '11th-12th September 2024',
        description: 'Welcome to Arena 3.0, where you will be tested for skill, strategy, and teamwork within the most adrenaline-pumpingaming event! Every match is one step closer to victory, with many rounds in store to challenge the participants within the game settings. The more teams present, the higher the competition level there is! Get your team together and become sharp with your strategies on how to win this battle. Are you ready for the ultimate win in the arena? May the games begin!',
        color: 'blue',
        imgo: [
            require('./dataJs/yugmak-24/ARENA3.0/arena1.png'),
            require('./dataJs/yugmak-24/ARENA3.0/arena2.jpeg'),
            require("./dataJs/Arena/WhatsApp Image 2022-07-23 at 2.25.18 AM.jpeg"),
            require("./dataJs/Arena/WhatsApp Image 2022-07-23 at 2.25.19 AM (2).jpeg"),
            require("./dataJs/Arena/WhatsApp Image 2022-07-23 at 2.25.32 AM.jpeg"),
        ]
    },
    {
        img: require('./Drishय.png'),
        eventname: 'Drishय',
        evantDate: '11th September 2024',
        description: 'Grab your crew and dive into our short film challenge, full of competitors with diverse ideas of innovation and cinematic excellence! Whether you’re an experienced filmmaker or a passionate newcomer, this is your golden opportunity to shine and prove yourself. Be Ready to redefine what’s victory! Lets turn this challenge into a triumph and show the world what you’re made of!',
        color: 'blue',
        imgo: [
            require('./dataJs/yugmak-24/Drishय/IMG_1384.png'),
            require('./dataJs/yugmak-24/Drishय/DSC_0063.JPG'),
            require('./dataJs/yugmak-24/Drishय/DSC_0169.JPG'),
            require('./dataJs/yugmak-24/Drishय/DSC_0359.JPG')
        ]
    },
    {
        img: require('./INNOVAक्षण.png'),
        eventname: 'INNOVAक्षण',
        evantDate: '10th September 2024',
        description: 'This unique start-up competition is designed to ignite creativity and set forth unimaginable ideas. At INNOVAक्षण, we believe that failure isn’t an endpoint; instead, it is the spark that ignites the most revolutionary ideas. INNOVAक्षणis an event dedicated to celebrating success and embracing every setback as a powerful stepping stone towards greatness. Every failure is a key to unlocking a new groundbreaking idea, and this event specifically focuses on challenging you to tap into your inner innovator to rethink, reimagine, and rebuild.',
        color: 'blue',
        imgo: [
            
            require('./dataJs/yugmak-24/INNOVAक्षण/DSC_0046.JPG'),
            require('./dataJs/yugmak-24/INNOVAक्षण/DSC_0346.JPG'), 
            require('./dataJs/yugmak-24/INNOVAक्षण/DSC_0454.JPG'),
            require('./dataJs/yugmak-24/INNOVAक्षण/DSC_0567.JPG'), 
            require('./dataJs/yugmak-24/INNOVAक्षण/DSC_0729.JPG'), 
        ]
    },
    {
        img: require('./Hackathon 8.0-1.jpg'),
        eventname: 'Hackathon 8.0',
        evantDate: '16th-17th March 2024',
        description: 'Hackathon 8.0 is another spectacular iteration of the Hackathon, that strives to break new ground and challenge conventional limits of innovation by emphasizing problem statements and themes, from all sectors including Healthcare, Education, Environment, Finance, Technology, Social Impact, Cybersecurity, Smart Cities, Entertainment and Agriculture. It endeavors to promote creative and innovative ideas by providing a supportive ecosystem, to guide participants in refining their skills and transforming their ideas into concrete prototypes or projects with. Through Hackathon 8.0, UPES-CSI is actively contributing to the Sustainable Development Goals (SDGs) by fostering innovation and technological solutions. The organization has received the award for best student chapter for seven consecutive years, demonstrating its commitment to promoting inclusivity and aligning with Quality Education and Gender Equality. By hosting events that attract diverse talents, UPES-CSI promotes inclusivity and supports the SDGs. Additionally, the emphasis on cutting-edge technologies and industry connections reflects the organization commitment to Industry, Innovation, and Infrastructure, further advancing sustainable development and creating a positive impact on society. real-world applications.',
        color: 'blue',
        imgo: [
            
            require('./dataJs/hackathon8.0/DSC_9311.JPG'),
            require('./dataJs/hackathon8.0/DSC_0369.JPG'),
            require('./dataJs/hackathon8.0/DSC_5036.JPG'),
            require('./dataJs/hackathon8.0/DSC00523.JPG'),
            require('./dataJs/hackathon8.0/DSC_0300.JPG'),
            require('./dataJs/hackathon8.0/DSC_5013.JPG')
        ]
    },
];
module.exports = data