const data={"info":<>
<h1 style={{fontSize:"1.8rem",fontWeight:"600",textAlign:"left"}} st>Akrit Negi</h1> 
<div className="d-flex justify-content-center align-items-center pratha" style={{gap:"5rem"}} >

<div className="right-box-info">

<br/>
<ul className="pra-det">
<li>I am Akriti Negi, a third-year BTech student specializing in Computer Science and Engineering with a focus on AI and Machine Learning. </li> 
<li>
    Design and VFX Head,UPES CSI.
</li>
<li>
    I have a deep interest in technology and innovation, and have successfully organized several events, including hackathons, to promote collaboration and creativity in the tech space.
</li>
</ul>
</div>
</div>
</>
 }

 export default data;