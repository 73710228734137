const data={"info":<>
<h1 style={{fontSize:"1.8rem",fontWeight:"600",textAlign:"left"}} st>Saksham Narang</h1> 
<div className="d-flex justify-content-center align-items-center pratha" style={{gap:"5rem"}} >

<div className="right-box-info">

<br/>
<ul className="pra-det">
    <li>Final year BCA student with specialization in Artificial Intelligence and Machine Learning</li>
    <li>
        Management and Records head, UPES-CSI.
    </li>
    <li>
    Organizer of several UPES events. 
    </li>
    <li>
        Organized an International Conference on Machine Learning and Data Engineering and an International Hackathon
    </li>
</ul>
</div>
</div>
</>
 }

 export default data;